.embroideryModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.embroideryContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    height: 80%;
}

.embroideryImageContainer {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.embroideryImageContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: .5rem;
}

.links {
    margin-top: 15px;
    margin-bottom: 15px;
}

.optionLinks {
    margin: 5px;
    padding: 5px;
    text-decoration: none;
    color: black;
    border-radius: .5rem;
    background-color: #ec2f3b;
}

.optionLinks:hover {
    background-color: lightpink;
    color: white;
}

@media (max-width: 768px) {
    .embroideryContainer {
        width: 90%;
        height: fit-content;
    }
    .embroideryImageContainer {
        height: 300px;
    }
}

@media (orientation: landscape) and (max-width: 1066px) {
    .embroideryContainer {
        width: fit-content;
        height: 90%;
    }
    .embroideryImageContainer {
        width: fit-content;
        height: 200px;
    }
}