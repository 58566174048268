.footerWrapper {
    align-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #ec2f3b;
}

.footerContainer {
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    align-items: center;
}

.footerContainer img {
    width: 3%;
    height: 3%;
}