.inquireWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 1rem;
}

.inquireMessageContainer {
    font-size: 15px;
    width: 50%;
}

.inquireFormContainer {
    width: 40%;
    height: 100%;
}

.inquireForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff965a;
    width: 100%;
    height: 100%;
    border-radius:  1.5rem;
}

input[type='text'],
input[type='email'] {
    background-color: #ff965a;
    border: none;
    border-bottom: 2px solid white;
    color: black;
    width: 60%;
    font-size: 20px;
}

input[type='text']::placeholder,
input[type='email']::placeholder {
    color: gray;
}

textarea {
    width: 90%;
    max-width: 100%;
    height: 100px;
    border-radius: .5rem;
    margin-bottom: 5px;
    font-size: 20px;
}

input[type='Submit']{
    border-radius: .5rem;
    font-size: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    border: none;
}

input[type='Submit']:hover{
    background-color: lightgreen;
}


@media (max-width: 435px) {
    .inquireFormContainer {
        width: 80%;
    }

    .inquireForm {
        width: 100%;
    }

    .inquireMessageContainer {
        width: 70%;
    }
}

@media (max-width: 820px) {
    .inquireFormContainer {
        width: 50%;
    }

    .inquireForm {
        width: 100%;
    }

    .inquireMessageContainer {
        width: 70%;
    }

    input[type='text'],
    input[type='email'] {
        width: 70%;
    }
}

@media (min-height: 393px) and (max-width: 1040px) and (orientation: landscape) {
    .inquireFormContainer {
        width: 50%;
    }

    .inquireForm {
        width: 100%;
    }

    .inquireMessageContainer {
        width: 70%;
    }

    input[type='text'],
    input[type='email'] {
        width: 70%;
    }
}

@media (min-height: 802px) and (max-width: 1180px) and (orientation: landscape) {
    .inquireFormContainer {
        width: 50%;
    }

    .inquireForm {
        width: 100%;
    }

    .inquireMessageContainer {
        width: 70%;
    }

    input[type='text'],
    input[type='email'],
    textarea {
        width: 70%;
        font-size: 18px;
    }
}

@media (min-height: 1558px) and (max-width: 975px) {
    .inquireFormContainer {
        width: 50%;
    }

    .inquireForm {
        width: 100%;
    }

    .inquireMessageContainer {
        width: 70%;
    }

    input[type='text'],
    input[type='email'],
    textarea {
        width: 70%;
        font-size: 18px;
    }
}