.homeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/src/Images/hayleeLogo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    justify-content: center;
    
}

.homeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 50%;
    border-radius: .5rem;
    padding: .5rem;
    background-color: rgba(203, 195, 195, 0.5);
}

.homeButtonContainer {
    justify-content: space-evenly;
}

.homeButton {
    background-color: #ec2f3b;
    color: black;
    text-decoration: none;
    border-radius: .5rem;
    padding: 2px;
    margin: 10px;
}

.homeButton:hover {
    background-color: #ff4d58;
    color: white;
}

@media (max-width: 820px) {
    .homeWrapper {
        width: 100%;
        background-size: cover;
        background-size: 100% 100%;
        width: 100%;
        height: 100vh;
    }
    .homeContainer {
        width: 90%;
    }
}