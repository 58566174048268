.knittingModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.knittedContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    height: 80%;
}

.knittedImageContainer {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.knittedImageContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: .5rem;
}

@media (orientation: landscape) and (max-width: 1066px) {
    .knittedContainer {
        width: 90%;
        height: 90%;
    }
    .knittedImageContainer {
        height: 200px;
    }
}