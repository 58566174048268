.aboutWrapper {
    width: 100%;
    height: 100%;
    justify-items: center;
}

.aboutContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
}

.aboutImageContainer,
.aboutTextContainer {
    justify-content: center;
    width: 50%;
    height: 100%;
    text-wrap: wrap;
}

.aboutImage {
    width: 50%;
    border-radius: 2.5rem;
}

.socialsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.socialsMessage {
    font-size: 25px;
}

.socials {
    width: 8rem;
}

@media (max-width: 820px){
    .aboutContainer {
        flex-direction: column;
    }
    .aboutImageContainer {
        width: 50%;
    }
    .aboutTextContainer {
        width: 80%;
    }
    .aboutImage {
        width: 100%;
    }
    .socials {
        width: 100%;
    }
}

@media (min-height: 393px) and (max-width: 1180px) and (orientation: landscape) {
    .aboutContainer {
        flex-direction: column;
    }
    .aboutImageContainer {
        width: 30%;
    }
    .aboutTextContainer {
        width: 80%;
    }
    .aboutImage {
        width: 100%;
    }
    .socials {
        width: 100%;
    }
}

@media (min-height: 1558px) and (max-width: 975px) {
    .aboutContainer {
        flex-direction: column;
    }
    .aboutImageContainer {
        width: 30%;
    }
    .aboutTextContainer {
        width: 80%;
    }
    .aboutImage {
        width: 100%;
    }
    .socials {
        width: 100%;
    }
}