.itemsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.headerMessage {
    font-size: 25px;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100;
    height: fit-content;
}

.portraits,
.crocheting,
.knitting,
.embroidery {
    display: flex;
    width: 60%;
    height: 20%;
    margin: 15px;
    justify-content: space-evenly;
    align-items: center;
    transition: transform 0.3s ease; 
    border-radius: .5rem;
}

.portraits img,
.crocheting img,
.knitting img,
.embroidery img {
    max-width: 30%;
    border-radius: 1rem;
    border: 2px solid #ec2f3b;
    transition: border-color 0.3s ease; 
}


.portraits:hover img,
.crocheting:hover img,
.knitting:hover img,
.embroidery:hover img {
    cursor: pointer;
    border-color: green; 
}

.portraits:hover,
.crocheting:hover,
.knitting:hover,
.embroidery:hover {
    cursor: pointer;
    transform: scale(1.05); 
    color: green; 
    background-color: whitesmoke;
}

.portraits,
.knitting {
    animation: fromRight 1.2s ease-in-out forwards;
}

.crocheting,
.embroidery{
    animation: fromLeft 1.2s ease-in-out forwards;
}

.crochetingLink,
.knittingLink,
.embroideryLink,
.portraitLink {
    display: contents;
    text-decoration: none;
    color: black;
}

@keyframes fromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}