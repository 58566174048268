.portraitsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.portraitsContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    height: 80%;
}

.carousel-root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
}


.portraitImageContainer {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portraitImageContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: .5rem;
}

.links {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.closeButtonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.closeButton {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: rgb(255, 105, 105);
    color: white;
}

@media (max-width: 768px) {
    .portraitsContainer {
        width: 90%;
        height: 90%;
    }
}

@media (orientation: landscape) and (max-width: 1066px) {
    .closeButton {
        top: 1rem;
        right: 1rem;
    }
    .portraitsModal {
        width: 100%;
        height: 100%;
    }
    .portraitsContainer {
        width: 90%;
        height: fit-content;
    }
    .portraitImageContainer {
        height: 190px;
        width: fit-content;
    }
}
