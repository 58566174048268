.navWrapper {
    background-color: #ec2f3b;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
}

.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: white;
    padding: 10px;
    width: 100%;
}

.dropDown {
    display: flex;
    width: 100%;
    height: fit-content;
    letter-spacing: .5px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.menu {
    transform: fadeIn 0.3s ease-in-out;
}

.menu.open {
    animation: fadeIn 0.3s ease-in-out;
}

.navBar {
    display: flex-end;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.dropDownButton img:hover {
    cursor: pointer;
}

.dropDownButton img {
    width: 100%;
    border: none;
    text-decoration: none;
}

.dropDownContainer {
    display: flex;
    flex-direction: column; /* Add this line */
    align-items: center;
    width: 100%;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.dropDownButton {
    width: 60%;
    background-color: #ec2f3b;
    border: none;
    padding: 0;
}

.link {
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.links {
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: .5rem;
}

.links:hover {
    color: #ff965a;
    border-bottom: 1px solid white;
}

@media (max-width: 820px) {
    .navWrapper {
        height: fit-content;
    }
    .dropDown {
        width: 100%;
        justify-content: center;
    }
    .link {
        font-size: 18px;
    }
}

@media (max-width: 435px) {
    .navWrapper {
        height: fit-content;
    }
    .dropDown {
        width: 100%;
        justify-content: center;
    }
    .dropDownButton img {
        width: 100%;
    }
    .link {
        font-size: 18px;
    }
}

@media (min-height: 393px) and (max-width: 1040px) and (orientation: landscape) {
    .navWrapper {
        height: fit-content;
    }
    .dropDown {
        width: 100%;
        justify-content: center;
    }
    .link {
        font-size: 18px;
    }
}

@media (min-height: 1558px) and (max-width: 975px) {
    .navWrapper {
        height: fit-content;
    }
    .dropDown {
        width: 100%;
        justify-content: center;
    }
    .link {
        font-size: 18px;
    }
}
